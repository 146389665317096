import React from "react"

import { PpcPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { AnchorNumber } from "components/main-header/AnchorNumber"
import "./tracking-button.module.scss"

interface TrackingButtonProps {
  isLandingPage: boolean
  customTrackingNum?: string
}

export const TrackingButton = ({isLandingPage, customTrackingNum}: TrackingButtonProps) => {
  const {
    customTrackingNumberCtaButtonText,
    trackingNumbers,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const { handleOpenModal } = useTrackingNumberModal()

  const onTrackingClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleOpenModal();
  }

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  return (
    <>
      { trackingNumbers.length > 0 &&
        trackingNumbers.map(({ trackingNumber, phoneNumber, id }, i) => {
          if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
          if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
          if (i > 0) return

          return (
            <span
              className="tracking__phone-button"
              key={ i }
              onClick={ trackingNumbers.length > 1 && !customTrackingNum
                ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onTrackingClick(e)
                : () => null
              }
            >
              <AnchorNumber
                showIcon
                classNameModifier="-hidden"
                label={ trackingNumberCTAText }
                key={ id }
                phoneNumber={ phoneNumber }
                svgIcon={ <PpcPhoneIcon /> }
                trackingNumber={ trackingNumber }
                isLandingPage = {isLandingPage}
                customTrackingNum={customTrackingNum}
              />
            </span>
          )
        }) }
    </>
  )
}
