import { useContext } from "react"
import {
  TrackingNumberModalContext,
  TrackingNumberModalContextData,
} from "contexts/TrackingNumberModalContext"

export const useTrackingNumberModal = (): TrackingNumberModalContextData => {
  const context = useContext(TrackingNumberModalContext)

  if (!context) {
    throw new Error(
      "useTrackingNumberModal must be used within SideNavProvider"
    )
  }

  return context
}
