import React from "react"
import { Swiper, SwiperProps } from "swiper/react"
import { EffectFade, Controller } from "swiper"

import "swiper/scss"
import "swiper/css/effect-fade"
import "swiper/css/controller"
import "swiper/scss/controller"

export const CSS_VARIABLES_COLORS_CONFIG: Record<string, string> = {
  // "--swiper-theme-color": `var(--primary)`,
}

const CSS_VARIABLES_NAVIGATION: Record<string, string> = {
  "--swiper-navigation-size": "32px",
  // "--swiper-navigation-color": "var(--primary)",
}

export const CSS_VARIABLES: Record<string, string> = {
  ...CSS_VARIABLES_COLORS_CONFIG,
  ...CSS_VARIABLES_NAVIGATION,
}

interface SliderProps extends SwiperProps {
  children: React.ReactNode,
}

export const SwiperSlider = ({ children, ...rest }: SliderProps) => {
  const swiperProps: SwiperProps = {
    initialSlide: 0,
    effect: "fade",
    grabCursor: true,
    modules: [Controller, EffectFade],
    slidesPerView: 1,
    style: {
      ...CSS_VARIABLES,
    },
    ...rest,
  }
  return (
    <>
      <Swiper {...swiperProps}>{children}</Swiper>
    </>
  )
}
