import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import React from "react"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"
import { HeroTab } from "./TabbedHero-types"

interface BackgroundImageProps {
  onHandleGetHeroBgImageUrl: (tab: HeroTab) => Record<string, string>
  prefix: GenerateVariationPrefixes
}

export const BackgroundImage = ({
  onHandleGetHeroBgImageUrl,
  prefix,
}: BackgroundImageProps) => {
  const { activeItem } = useTabbedContainer<HeroTab>()

  return (
    <div
      className={`${prefix}__background-image`}
      style={onHandleGetHeroBgImageUrl(activeItem)}
    />
  )
}
