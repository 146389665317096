import React, { ReactNode } from "react"
import { getPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface AnchorNumberProps {
  phoneNumber?: string
  trackingNumber?: string
  label?: string
  svgIcon?: string | ReactNode
  classNameModifier?: string
  showIcon?: boolean
  isLandingPage: boolean
  customTrackingNum?: string
}

type SvgHtml = {
  dangerouslySetInnerHTML: {
    __html: string
  }
} | {}

export const AnchorNumber = ({
  phoneNumber,
  trackingNumber,
  label,
  svgIcon,
  classNameModifier = "",
  showIcon = true,
  isLandingPage = false,
  customTrackingNum
}: AnchorNumberProps) => {
  const { usePhoneNumberNotTrackingNumber } = useGeneralSettings()

  const elSvg: ReactNode = svgIcon !== 'string'
    ? svgIcon
    : undefined
  const htmlSvg: SvgHtml = svgIcon === 'string'
    ? {dangerouslySetInnerHTML: { __html: svgIcon }}
    : {}
  const telephoneNum = (isLandingPage && customTrackingNum) ?
    customTrackingNum :
      usePhoneNumberNotTrackingNumber ?
      phoneNumber : trackingNumber

  return (
    <a
      className={`fr-header__phone-${classNameModifier}`}
      href={`tel:${telephoneNum}`}
    >
      {label && (
        <div className="fr-header__extra-call-text">
          {label}
        </div>
      )}
      {showIcon && svgIcon && (
        <span
          {...htmlSvg}
          className="phone-svg-container"
          contentEditable="false"
        >
            { elSvg }
        </span>
      )}
      {showIcon && !svgIcon && <>{getPhoneIcon()}</>}
      <h5 className={`fr-header__phone-${classNameModifier}__text`}>
        {telephoneNum}
      </h5>
    </a>
  )
}
