import React from "react"
import { Banner } from "components/hero/HeroBanner-types"
import "components/hero/hero-common.module.scss"
import "./ppc-hero.module.scss"

import { BackgroundImage } from "./assets/BackgroundImage"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { FormBuilder } from "components/forms/FormBuilder"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"
import { HeroContentBaseClassName } from "../HeroContent"
import { Image } from "components/image/Image"
import { useHeroComponents } from "../hooks/useHeroComponents"

interface HeroProps {
  banner: Banner
}

export const PpcHero = ({ banner }: HeroProps) => {
  const { components, heading, secondary_image } = banner

  const { form, hasForm } = useHeroComponents(components?.modular_content)

  const prefix: GenerateVariationPrefixes = "fr-ppc-hero"

  const bannerContentBaseClassName =
    `${prefix}__banner-content` as HeroContentBaseClassName

  const contentClassName =
    secondary_image && secondary_image?.value?.length > 0
      ? `${bannerContentBaseClassName}-with-image`
      : `${bannerContentBaseClassName}`

  const header = heading ? heading.value : undefined

  const secondaryImage =
    secondary_image && secondary_image?.value?.length > 0
      ? secondary_image?.value[0]
      : undefined

  return (
    <>
      <BannerWrapper
        hasBackgroundContainer
        backgroundImageComponent={<BackgroundImage />}
        containerSize="default"
        contentClassName={contentClassName}
        prefix={prefix}
      >
        {header && (
          <h1 className={`${prefix}__heading ${contentClassName}__heading`}>
            {header}
          </h1>
        )}
        <div className={`fr-container__column ${contentClassName}__column`}>
          {hasForm && (
            <div className={`${prefix}__form ${contentClassName}__form`}>
              <FormBuilder
                isLandingPage
                ctaText={form?.submit_button_text?.value}
                customId={form?.custom_id?.value}
                fields={form.fields}
                formGridStyle="landing_page"
                heading={form.heading?.value}
                subHeading={form.sub_heading?.value}
                submissionMessageBody={form?.submission_message_body?.value}
                submissionMessageHeading={
                  form?.submission_message_heading?.value
                }
              />
            </div>
          )}
        </div>
        <div className={`fr-container__column ${contentClassName}__column`}>
          {secondaryImage && (
            <div
              className={`${prefix}__secondary-image ${contentClassName}__secondary-image`}
            >
              <Image image={secondaryImage} />
            </div>
          )}
        </div>
      </BannerWrapper>
    </>
  )
}
