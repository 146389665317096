import React, { useEffect, ReactNode } from "react"
import { Helmet } from "react-helmet"

import { useFormFields } from "hooks/useFormFields"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useLogos } from "hooks/queries/settings/useLogos"
import { useNavigationItems } from "hooks/useNavigationItems"
import { useWindowDimensions } from "hooks/useWindowDimensions"

import { setTrackingCookies } from "assets/helper/setTrackingCookies"
import { TrackingButton } from "components/tracking-button"
import { TrackingNumberModalContainer } from "components/modal/TrackingNumberModal/TrackingNumberModalContainer"
import { TrackingNumberModalProvider } from "contexts/TrackingNumberModalContext"
import { appendScript, appendInlineScript } from "assets/helper/appendScript"
import { FormModal } from "./modal/FormModal/FormModal"
import { Footer } from "components/footer/Footer"
import { Header } from "components/main-header/Header"
import { LayoutContext } from "contexts/components/LayoutContext"

import "assets/scss/4-templates/landing-page-template.module.scss"

interface LandingPageLayoutProps {
  children: ReactNode
  linkedPagesUrlPathCodex: Record<string, string>
  location: string
  mobilePreload?: string
  parentSlug?: string
  pathName: string
  isLandingPage: boolean
  customTrackingNum?: string
}

export const LandingPageLayout = ({
  children,
  linkedPagesUrlPathCodex,
  location,
  mobilePreload,
  parentSlug,
  pathName,
  customTrackingNum,
  isLandingPage
}: LandingPageLayoutProps) => {
  const { height, size } = useWindowDimensions()
  const slug =
    location?.startsWith("/") && location !== "/"
      ? location.substring(1)
      : "home"

  const slugClassModifier =
    slug === "/"
      ? "home"
      : slug

  let scrollClass = ""

  if (height > 45) scrollClass = "page-content--scroll-version"

  const { customCSSUrl, scripts } = useGeneralSettings()
  const { footerNavigation, creditsNavigation } = useNavigationItems()
  const { footerLogo, headerLogo } = useLogos()
  const { isFormModalOpen } = useFormFields()

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" &&
          appendScript({
            id: `globalScript-${index + 1}`,
            scriptToAppend: script.elements.src_value?.value || "",
            isAsync: true,
          })

        script.elements.script?.value !== "" &&
          appendInlineScript({
            id: `globalScriptInline-${index + 1}`,
            scriptToAppend: script.elements.script?.value || "",
            isAsync: false,
          })
      })
    }
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug,
      }}
    >
      <div className={ `wrapper wrapper--${slugClassModifier}` }>
        <Helmet>
          { customCSSUrl && <link rel="stylesheet" href={ customCSSUrl } /> }
          { mobilePreload && size < 768 && (
            <link rel="preload" as="image" href={ mobilePreload } />
          ) }
        </Helmet>

        <TrackingNumberModalProvider>
          <TrackingNumberModalContainer>
            <Header
              isLandingPage
              hasToggleMenu={ false }
              logo={ headerLogo }
            >
              <TrackingButton
              isLandingPage
              customTrackingNum={customTrackingNum}
              />
            </Header>
          </TrackingNumberModalContainer>
        </TrackingNumberModalProvider>

        { isFormModalOpen && <FormModal /> }

        <main className={ `page-content--landing-page ${scrollClass}` }>
          { children }
        </main>

        <Footer
          creditsNavigation={ creditsNavigation }
          footerNavigation={ footerNavigation }
          logo={ footerLogo }
          isLandingPage
          customTrackingNum={customTrackingNum}
        />
      </div>
    </LayoutContext.Provider>
  )
}
