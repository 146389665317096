import React from "react"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"

interface SecondaryImageProps {
  image: ImageBuilder
  prefix: string
}

export const SecondaryImage = ({ image, prefix }: SecondaryImageProps) => {
  return (
    <div className={`${prefix}__secondary-image-container`}>
      <Image
        image={image}
        className={`${prefix}__secondary-image`}
        objectFit="contain"
      />
    </div>
  )
}
