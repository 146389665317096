import React, { HTMLAttributes } from "react"

interface SVGComponent extends HTMLAttributes<HTMLOrSVGElement> {}

export const BackgroundImage = ({ ...rest }: SVGComponent) => (
  <div className="fr-ppc-hero__background-component">
    <svg
      viewBox="0 0 1378.75 915.973"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M450.06,103.434C318.077,60.242,216.548-114.821,38.775,119.856c-81.239,166.219-7.778,311.529,0,550.159-8.486,245.875-69.914,362.318,0,510.839s227.02,193.719,382.285,195.416c149.375.518,503.384,53.869,491.985-362.286-56.316-362.093-90.911-294.287-69.8-502.309,21.98-216.634,112.15-256.074,54.842-390.462C734.022-162.488,582.042,146.625,450.06,103.434Z"
        id="ppc-hero-bg"
        transform="translate(1378.75 -0.878) rotate(90)"
        x="0"
        y="0"
      />
    </svg>
  </div>
)
