import React, {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useRef,
} from "react"

export type TrackingNumberModalContextData = {
  handleCloseModal: () => void
  handleOpenModal: () => void
  isModalOpen: boolean
}

interface TrackingNumberModalProviderProps {
  children: ReactNode
}

const TrackingNumberModalContext = createContext(
  {} as TrackingNumberModalContextData
)

const TrackingNumberModalProvider = ({
  children,
}: TrackingNumberModalProviderProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const isClosing = useRef<boolean>(false)

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    if (!isModalOpen) return
    isClosing.current = true
    setTimeout(() => {
      if (isModalOpen) {
        isClosing.current = false
        setModalOpen(false)
      }
    }, 100)
  }, [isModalOpen])

  return (
    <TrackingNumberModalContext.Provider
      value={{ isModalOpen, handleOpenModal, handleCloseModal }}
    >
      {children}
    </TrackingNumberModalContext.Provider>
  )
}

export { TrackingNumberModalProvider, TrackingNumberModalContext }
