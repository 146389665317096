import React from "react"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { TrackingNumberModal } from "./TrackingNumberModal"

interface TrackingNumberModalContainerProps {
  children: React.ReactNode
}

export const TrackingNumberModalContainer = ({
  children,
}: TrackingNumberModalContainerProps) => {
  const { isModalOpen } = useTrackingNumberModal()
  return (
    <>
      <>{children}</>
      {isModalOpen && <TrackingNumberModal />}
    </>
  )
}
