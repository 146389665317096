import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { GoogleIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"

import { ExternalLink } from "components/external-link/ExternalLink"
import "./google-reviews.module.scss"

interface GoogleReviewProps {
  url: string
}

type GraphQLData = {
  average: {
    averageRating: number
    isDevelopment: boolean
    hasNoCompass: boolean
  }
  count: {
    totalReviews: number
  }
}

export const GoogleReview = ({ url }: GoogleReviewProps) => {
  const { average } = useStaticQuery<GraphQLData>(getData)
  const { isAlertEnabled } = useGeneralSettings()

  const { averageRating, isDevelopment, hasNoCompass } = average

  if ((!isDevelopment && hasNoCompass) || averageRating === 0) return null

  const averageRatingFormatted = averageRating && averageRating.toFixed(1)

  const disableAnimationClass = isAlertEnabled
    ? "fr-google-review__animation--disabled"
    : ""

  return (
    <ExternalLink
      className={`u-flex-space-between fr-google-review ${disableAnimationClass}`}
      href={url}
    >
      <GoogleIcon className="fr-google-review__icon" />
      <h3 className="fr-google-review__rating">{`${averageRatingFormatted} Stars Average Google Rating`}</h3>
    </ExternalLink>
  )
}

const getData = graphql`
  query {
    average {
      averageRating
      isDevelopment
      hasNoCompass
    }
    count {
      totalReviews
    }
  }
`

export const query = graphql`
  fragment GoogleReviewsFragment on kontent_item_settings_elements {
    google_reviews_url {
      value
    }
  }
`
