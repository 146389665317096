import React, { useRef, useState } from "react"
import { SwiperSlider } from "./Slider"
import { Swiper as SwiperClass } from "swiper"
import { SwiperSlide } from "swiper/react"

import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { HeroTab } from "./TabbedHero-types"
import { HeroContent } from "../HeroContent"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { GoogleReview } from "components/google-reviews-banner/GoogleReview"

import "./tabbed-hero.module.scss"
import { useHeroComponents } from "../hooks/useHeroComponents"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import { SecondaryImage } from "../SecondaryImage/SecondaryImage"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
  hasSecondaryImage: boolean
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  hasSecondaryImage,
}: TabbedHeroBannerContentProps) => {
  const tabsRef = useRef<HTMLDivElement>(null)
  const { googleReviewsUrl } = useIntegrationsSettings()
  const { allContent, handleChangeActiveTab } = useTabbedContainer<HeroTab>()

  const [swiper, setSwiper] = useState<SwiperClass>()

  const slideTo = (index: number) => {
    swiper?.slideTo(index, 100)
  }

  return (
    <>
      <SwiperSlider
        className={`fr-tabbed-hero-slider`}
        onSwiper={setSwiper}
        onActiveIndexChange={swiper =>
          handleChangeActiveTab(swiper.activeIndex)
        }
      >
        <div
          ref={tabsRef}
          slot="container-start"
          className="fr-tabbed-hero-slider__tabs-wrapper"
        >
          {allContent.map(({ elements, id }, i) => {
            if (!elements) return
            const { tab_title } = elements

            return (
              <button
                className={`button--hero-tab fr-tabbed-hero-slider__tab ${
                  swiper?.activeIndex === i ? "active" : ""
                }`}
                key={id}
                onClick={() => slideTo(i)}
              >
                <span>{tab_title?.value}</span>
              </button>
            )
          })}
        </div>

        {allContent.map(({ elements }, i) => {
          if (!elements) return

          const {
            buttons,
            heading,
            components_override,
            secondary_image,
            secondary_image_override,
            sub_heading,
            tagline,
          } = elements

          const { highlights } = useHeroComponents(
            components_override?.modular_content
          )

          const secondaryImage =
            secondary_image_override?.value[0] || secondary_image?.value[0]

          return (
            <SwiperSlide key={i} virtualIndex={i}>
              <div className="fr-hero__body">
                <div className="fr-hero__body--left">
                  {hasSecondaryImage && (
                    <div
                      className="fr-tabbed-hero-slider__tabs-placeholder"
                      style={{
                        height: `${tabsRef.current?.clientHeight || 0}px`,
                      }}
                    />
                  )}

                  <GoogleReview url={googleReviewsUrl} />

                  <HeroContent
                    baseClassName="fr-hero__banner-content"
                    buttons={buttons?.value}
                    heading={heading?.value}
                    subHeading={sub_heading}
                    tagline={tagline}
                  />
                </div>

                <div className="fr-hero__body--right">
                  {secondaryImage && (
                    <SecondaryImage image={secondaryImage} prefix="fr-hero" />
                  )}

                  {!hasSecondaryImage && highlights && highlights.length > 0 && (
                    <div className={`fr-hero__highlights`}>
                      <HeroHighlights highlights={highlights} />
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </SwiperSlider>
    </>
  )
}
