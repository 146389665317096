import { CouponType } from "components/coupons/coupons"
import { KontentItemFormElements } from "components/forms/formBuilder-types"
import { Component, HighlightElements } from "components/hero/HeroBanner-types"

export const useHeroComponents = (components?: Component[]) => {
  const highlights = (
    components?.filter(
      component => component?.system?.type === "highlights_group"
    )[0]?.elements as HighlightElements
  )?.highlights?.modular_content

  const form = components?.filter(
    component => component?.system?.type === "form"
  )[0]?.elements as KontentItemFormElements

  const coupon = components?.filter(
    component => component?.system?.type === "coupon"
  )[0]?.elements as CouponType

  const hasHighlights = !!highlights && highlights?.length > 0
  const hasForm = !!form && form?.fields.modular_content.length > 0
  const hasCoupon = !!coupon

  return {
    highlights,
    form,
    coupon,
    hasHighlights,
    hasForm,
    hasCoupon,
  }
}
