import React, { useCallback, useMemo } from "react"
import { graphql } from "gatsby"
import "components/hero/hero-common.module.scss"
import "components/hero/HeroBanner/hero.module.scss"
import "./tabbed-hero-banner.module.scss"

import { CmsAssetImage } from "_types/AssetsImage"
import { HeroContent, HeroContentBaseClassName } from "../HeroContent"
import { HeroTab } from "./TabbedHero-types"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import { useHeroComponents } from "../hooks/useHeroComponents"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"
import { BackgroundImage } from "./BackgroundImage"
import { Divider } from "../Divider"

interface TabbedHeroBannerProps {
  banner: {
    background_image?: CmsAssetImage
    hero_tabs: {
      modular_content: HeroTab[]
    }
    secondary_image?: CmsAssetImage
  }
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { background_image, secondary_image, hero_tabs } = banner

  const prefix: GenerateVariationPrefixes = "fr-hero"

  const wrapperClassName = `fr-tabbed-hero`

  const bannerContentBaseClassName =
    `${prefix}__banner-content` as HeroContentBaseClassName

  const hasAtleastOneSecondaryImgInTab = useMemo(
    () =>
      hero_tabs?.modular_content?.some(
        ({ elements }) => !!elements?.secondary_image_override?.value[0]
      ),
    [hero_tabs]
  )

  const hasSecondaryImage =
    (secondary_image && secondary_image?.value?.length > 0) ||
    hasAtleastOneSecondaryImgInTab

  const contentClassName = hasSecondaryImage
    ? `${bannerContentBaseClassName}-with-image`
    : bannerContentBaseClassName

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const {
        heading,
        sub_heading,
        components_override,
        buttons,
        secondary_image_override,
        tagline,
      } = activeTabContent?.elements

      const { highlights } = useHeroComponents(
        components_override?.modular_content
      )

      return (
        <HeroContent
          baseClassName={prefix}
          buttons={buttons?.value}
          heading={heading?.value}
          highlights={highlights}
          subHeading={sub_heading}
          secondaryImage={
            secondary_image_override?.value[0] || secondary_image?.value[0]
          }
          tagline={tagline}
        />
      )
    },
    [secondary_image, contentClassName]
  )

  const handleGetHeroBgImageUrl = useCallback(
    (activeTabContent?: HeroTab): Record<string, string> => {
      if (!activeTabContent) return {}

      const backgroundImageUrl =
        activeTabContent?.elements?.background_image_override?.value[0]?.url ||
        background_image?.value[0]?.url

      const backgroundImageCSSVariable = useBackgroundImage({
        backgroundImageUrl,
        variable: "hero",
      })

      return backgroundImageCSSVariable
    },
    [background_image]
  )

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <BannerWrapper
        contentClassName={contentClassName}
        prefix={prefix}
        wrapperClassName={wrapperClassName}
        backgroundImageComponent={
          <BackgroundImage
            prefix={prefix}
            onHandleGetHeroBgImageUrl={handleGetHeroBgImageUrl}
          />
        }
      >
        <TabbedHeroBannerContent
          contentClassName={contentClassName}
          handleActiveTabContent={getActiveTabContent}
          hasSecondaryImage={hasSecondaryImage}
        />
      </BannerWrapper>
      <Divider />
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
        type
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components_override {
        modular_content {
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
